export default [
  {
    title: 'Barcode',
    children: [
      { title: 'All Barcodes', route: { name: 'list-barcodes' }, icon: 'ListIcon' },
      { title: 'Registered Barcodes', route: { name: 'list-registered-barcodes' }, icon: 'CheckSquareIcon' },
      { title: 'Add Barcode', route: { name: 'add-barcode' }, icon: 'PlusSquareIcon' },
      { title: 'Kit Pickup Requests', route: { name: 'list-barcode-pickup' }, icon: 'BookOpenIcon' },
      { title: 'View TAT', route: { name: 'TAT-view' }, icon: 'UserIcon' },
      { title: 'Wet Lab Status', route: { name: 'wetLab-data' }, icon: 'StarIcon' },
    ],
  },
  {
    title: 'Users',
    children: [
      { title: 'Consumers', route: { name: 'list-consumers' }, icon: 'UsersIcon' },
      { title: 'Kit Users', route: { name: 'list-kit-users' }, icon: 'UserCheckIcon' },
    ],
  },
  {
    title: 'Reports',
    children: [
      { title: 'Pending reports', route: { name: 'list-pending-reports' }, icon: 'UsersIcon' },
      { title: 'Final Review', route: { name: 'list-final-review' }, icon: 'UserCheckIcon' },
    ],
  },
  {
    title: 'Questionnaire',
    route: { name: 'list-questionnaire-responses' },
  },
  {
    title: 'Feedback',
    route: { name: 'list-feedbacks' },
  },
]
